import { useEffect, useState } from 'react'

import { LoginFlow, VerificationFlow } from '@ory/client'
import dayjs from 'dayjs'
import { useGetUserKratosCredentialsLazyQuery } from 'graph/generated/payments/graphql-types'
import useBroadcastChannel, {
  BroadcastChannelEvents
} from 'lib/hooks/useBroadcastChannel'
import { useRouter } from 'next/router'
import theme from 'utils/theme'

import DefaultOryForm from '@/forms/DefaultOryForm'
import LoginForm from '@/forms/LoginForm'
import AuthFlowLayout from '@/layouts/AuthFlowLayout'
import { EmailCodePreview } from '@/ui/ImagePreview'
import ory, {
  handleFlowError,
  handleGetFlowError,
  loginSubmit,
  getIdentifierFromFlowContext
} from '@/utils/ory'

const LoginLayout = ({
  userEmail,
  credentials: userCredentials
}: {
  userEmail: string
  credentials: string[]
}) => {
  const { receivedMessage, sendMessage } = useBroadcastChannel<LoginFlow>(
    BroadcastChannelEvents.LOGIN
  )
  const [getCredentials] = useGetUserKratosCredentialsLazyQuery()
  const router = useRouter()
  const { isReady, query } = router

  const { return_to: returnTo, flow: flowId, refresh } = query

  const [flow, setFlow] = useState<LoginFlow>()
  const [credentials, setCredentials] = useState<string[]>(userCredentials)
  const loginEmail = userEmail || getIdentifierFromFlowContext(flow)

  const expiryTimeStamp = flow?.expires_at

  const onSubmit = loginSubmit(flow, router, setFlow)

  useEffect(() => {
    if (!receivedMessage) return
    if (flow.id === receivedMessage.id) return

    setFlow(receivedMessage)
  }, [receivedMessage])

  useEffect(() => {
    if (!flow) return

    sendMessage(flow)
  }, [flow])

  useEffect(() => {
    if (!isReady || flow) {
      return
    }

    if (flowId) {
      ory
        .getLoginFlow({ id: String(flowId) })
        .then(({ data }) => setFlow(data))
        .catch(handleGetFlowError(router, 'login', setFlow))
      return
    }

    ory
      .createBrowserLoginFlow({
        refresh: Boolean(refresh),
        returnTo: returnTo ? String(returnTo) : undefined
      })
      .then(({ data }) => setFlow(data))
      .catch(err => handleFlowError(router, 'login', setFlow)(err))
  }, [router])

  // Schedule a refresh if the flow is about to expire
  useEffect(() => {
    if (expiryTimeStamp) {
      const currentTime = dayjs()
      const expiryTime = dayjs(expiryTimeStamp)

      if (currentTime.isBefore(expiryTime)) {
        // Calculate the time until expiry
        const timeUntilExpiry = expiryTime.diff(currentTime, 'second')

        // Schedule a refresh when the flow is about to expire
        const refreshTimeout = setTimeout(() => {
          ory
            .createBrowserLoginFlow({
              refresh: Boolean(refresh),
              returnTo: returnTo ? String(returnTo) : undefined
            })
            .then(({ data }) => setFlow(data))
            .catch(err => handleFlowError(router, 'login', setFlow)(err))
        }, timeUntilExpiry * 1000)

        return () => clearTimeout(refreshTimeout)
      }
    }
  }, [expiryTimeStamp, flow, router])

  // Get user credentials if it's not already loaded
  useEffect(() => {
    if (!flow || !loginEmail || credentials.length > 0) return

    const handleGetCredentials = async () => {
      const resp = await getCredentials({
        variables: {
          identifier: loginEmail
        }
      })

      const data = resp.data?.GetUserKratosCredentials.credentials || []
      setCredentials(data)
    }

    handleGetCredentials()
  }, [flow])

  // "Please complete the second authentication challenge."
  useEffect(() => {
    if (flow?.ui?.messages?.some(m => m.id === 1010004)) {
      const returnTo = (query.return_to as string) || flow.return_to
      router.push(`/mfa?aal=aal2${returnTo ? `&return_to=${returnTo}` : ''}`)
    }
  }, [flow, router])

  if ((flow as VerificationFlow)?.state === 'sent_email') {
    return (
      <AuthFlowLayout
        title='Welcome back!'
        description='Please enter the 6-digit code from your email.'
        titleImage={<EmailCodePreview />}
        userEmail={loginEmail}
      >
        <DefaultOryForm
          onSubmit={onSubmit}
          flow={flow}
          loadingProps={{ formItems: 1 }}
          hideGlobalMessages
        />
        <p
          css={{
            color: theme.colors.surface400,
            fontSize: theme.typography.body.sizes.sm
          }}
        >
          If you have not received an email, check the spelling of the address
          and retry the login.
        </p>
      </AuthFlowLayout>
    )
  }

  return (
    <AuthFlowLayout
      userEmail={loginEmail}
      title='Welcome back!'
      description='Sign in with one of the options below.'
    >
      <LoginForm
        userEmail={loginEmail}
        onSubmit={onSubmit}
        flow={flow}
        credentials={credentials}
      />
    </AuthFlowLayout>
  )
}

export default LoginLayout
