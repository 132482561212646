import { useEffect, useState } from 'react'

enum BroadcastChannelEvents {
  LOGIN = 'login'
}

// Ref: https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
const useBroadcastChannel = <T>(channelName: BroadcastChannelEvents) => {
  const [receivedMessage, setReceivedMessage] = useState<T | null>(null)

  useEffect(() => {
    const channel = new BroadcastChannel(channelName)

    channel.onmessage = e => {
      setReceivedMessage(e.data as T)
    }

    return () => {
      channel.close()
    }
  }, [channelName])

  const sendMessage = (message: T) => {
    const channel = new BroadcastChannel(channelName)
    channel.postMessage(message)
  }

  return { receivedMessage, sendMessage }
}

export default useBroadcastChannel
export { BroadcastChannelEvents }
